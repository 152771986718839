import { render, staticRenderFns } from "./ParticlesBanner.vue?vue&type=template&id=31f08fec&scoped=true"
import script from "./ParticlesBanner.vue?vue&type=script&lang=js"
export * from "./ParticlesBanner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f08fec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31f08fec')) {
      api.createRecord('31f08fec', component.options)
    } else {
      api.reload('31f08fec', component.options)
    }
    module.hot.accept("./ParticlesBanner.vue?vue&type=template&id=31f08fec&scoped=true", function () {
      api.rerender('31f08fec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Auth/ParticlesBanner/ParticlesBanner.vue"
export default component.exports