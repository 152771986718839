import { render, staticRenderFns } from "./DashboardHotTopicCard.vue?vue&type=template&id=89e7cfd8"
import script from "./DashboardHotTopicCard.vue?vue&type=script&lang=js"
export * from "./DashboardHotTopicCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89e7cfd8')) {
      api.createRecord('89e7cfd8', component.options)
    } else {
      api.reload('89e7cfd8', component.options)
    }
    module.hot.accept("./DashboardHotTopicCard.vue?vue&type=template&id=89e7cfd8", function () {
      api.rerender('89e7cfd8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/HotTopicCard/DashboardHotTopicCard.vue"
export default component.exports