// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/authBanner2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes zoomIn8-data-v-98093c82 {\nfrom {\n    opacity: 0;\n    -webkit-transform: scale3d(0.1, 0.1, 0.1);\n            transform: scale3d(0.1, 0.1, 0.1);\n}\n100% {\n    opacity: 1;\n}\n}\n@keyframes zoomIn8-data-v-98093c82 {\nfrom {\n    opacity: 0;\n    -webkit-transform: scale3d(0.1, 0.1, 0.1);\n            transform: scale3d(0.1, 0.1, 0.1);\n}\n100% {\n    opacity: 1;\n}\n}\n.wrapper-full-page .zoomIn[data-v-98093c82] {\n  -webkit-animation-name: zoomIn8-data-v-98093c82;\n          animation-name: zoomIn8-data-v-98093c82;\n}\n@-webkit-keyframes zoomOut8-data-v-98093c82 {\nfrom {\n    opacity: 1;\n    -webkit-transform: scale3d(0.7, 0.7, 0.7);\n            transform: scale3d(0.7, 0.7, 0.7);\n}\nto {\n    opacity: 0;\n    -webkit-transform: scale3d(0.46, 0.46, 0.46);\n            transform: scale3d(0.46, 0.46, 0.46);\n}\n}\n@keyframes zoomOut8-data-v-98093c82 {\nfrom {\n    opacity: 1;\n    -webkit-transform: scale3d(0.7, 0.7, 0.7);\n            transform: scale3d(0.7, 0.7, 0.7);\n}\nto {\n    opacity: 0;\n    -webkit-transform: scale3d(0.46, 0.46, 0.46);\n            transform: scale3d(0.46, 0.46, 0.46);\n}\n}\n.wrapper-full-page .zoomOut[data-v-98093c82] {\n  -webkit-animation-name: zoomOut8-data-v-98093c82;\n          animation-name: zoomOut8-data-v-98093c82;\n}\n.auth-layout__banner[data-v-98093c82] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.custom-layout-item[data-v-98093c82] {\n  padding: 0px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
