import { render, staticRenderFns } from "./CustomerAssetsSummary.vue?vue&type=template&id=81b086e4"
import script from "./CustomerAssetsSummary.vue?vue&type=script&lang=js"
export * from "./CustomerAssetsSummary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81b086e4')) {
      api.createRecord('81b086e4', component.options)
    } else {
      api.reload('81b086e4', component.options)
    }
    module.hot.accept("./CustomerAssetsSummary.vue?vue&type=template&id=81b086e4", function () {
      api.rerender('81b086e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsSummary/CustomerAssetsSummary.vue"
export default component.exports